/**
 * Billing Editor:
 * This editor will be available to mc-users that want to sale
 * a product to visitors on a published landing page.
 */
define(["dojo-proxy-loader?name=dojo/_base/declare!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/lang!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/_base/array!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/on!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-style!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-class!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-attr!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/dom-construct!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo/html", "dojo-proxy-loader?name=dojo/dom-form!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "dojo-proxy-loader?name=dojo/query!/opt/mailchimp/current/node_modules/@mc/webpack-plugin-legacy-dojo/src/modules/noop-module", "mojo/page/request", "dijit/_WidgetBase", "dijit/_TemplatedMixin", "velocity/velocity", "velocity/velocity.ui", "dojo/NodeList-traverse"], function (declare, lang, array, on, dom, domStyle, domClass, domAttr, domConstruct, html, domForm, query, request, _WidgetBase, _TemplatedMixin, Velocity) {
  var _ValidationMethods = declare([], {
    /* Field Validation Methods */
    isEmpty: function (input) {
      var valid;
      if (input.type === "checkbox" || input.tagName.toLowerCase() === "option") {
        valid = typeof valid === "boolean" ? valid && !this._checked(input) : !this._checked(input);
      } else {
        valid = typeof valid === "boolean" ? valid && this._empty(input.value) : this._empty(input.value);
      }
      return valid;
    },
    isEmail: function (input) {
      return this._email(input.value);
    },
    /* Utility Methods */
    _empty: function (value) {
      return value === null || typeof value === "undefined" || value === "";
    },
    _checked: function (node) {
      return node.checked || node.selected && !this._empty(node.value);
    },
    _email: function (value) {
      return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value);
    }
  });
  var _ValidationMixin = declare([_ValidationMethods], {
    /**
     *  Validate input field and return any errors
     *
     * @param {*} input - form field to validate
     * @returns {boolean} - if valid input return true
     */
    validateField: function (input) {
      var validData = false;
      var valid, message;

      // Skip inputs with formnovalidate attribute
      if (domAttr.has(input, "formnovalidate")) {
        return;
      }
      switch (input.type) {
        case "text":
          valid = !this.isEmpty(input);
          message = "This field is required.";
          break;
        case "email":
          valid = this.isEmail(input);
          message = "Please enter a valid email address.";
          break;
        default:
          valid = true;
      }
      if (!valid) {
        // Show errors
        this._showErrorNode(input, message);
      } else {
        // Hide errors
        this._hideErrorNode(input);
        // Return true
        validData = true;
      }
      return validData;
    },
    _showErrorNode: function (input, message) {
      var inputNode = dom.byId(input);
      var errorNode = query(inputNode).siblings(".invalid-error")[0];
      if (errorNode) {
        // Add invalid class
        domClass.add(input, "invalid");

        // Set/Show error message
        html.set(errorNode, message);
        this._show(errorNode, "fade");
      }
    },
    _hideErrorNode: function (input) {
      var inputNode = dom.byId(input);
      var errorNode = query(inputNode).siblings(".invalid-error")[0];
      if (errorNode) {
        // Remove invalid class
        domClass.remove(input, "invalid");

        // Clear/Hide error message
        html.set(errorNode, "");
        this._hide(errorNode, "fade");
      }
    }
  });
  var _SummaryBlock = declare([_WidgetBase, _TemplatedMixin], {
    templateString: "<div>" + "<div>${firstName} ${lastName}</div>" + "<div>${address1} ${address2}</div>" + "<div>${city}, ${state} ${zip}</div>" + "</div>"
  });
  return declare([_WidgetBase, _TemplatedMixin, _ValidationMixin], {
    // Template passed from server
    templateString: "<div></div>",
    // Create initial data state for editor
    state: {
      step: "shipping"
    },
    // Available editor steps
    steps: ["shipping", "billing", "payment", "confirmation"],
    // Array of shipping input nodes
    shippingInputs: [],
    // Array of billing input nodes
    billingInputs: [],
    // Stripe Elements object that contains payment inputs
    paymentInputs: null,
    // Boolean for same as shipping checkbox
    sameAsShipping: true,
    // Square or Stripe Payment Form
    paymentForm: null,
    // Stripe payment intent secret used to complete purchase
    paymentIntentSecret: null,
    // Stripe payment method secret used to complete purchase
    paymentMethodSecret: null,
    postCreate: function () {
      // Query payment button and attach billing editor event
      var editorButton = query('a[id^="payment_button"]')[0];
      if (editorButton) {
        on(editorButton, "click", lang.hitch(this, function (e) {
          e.preventDefault();
          e.stopPropagation();
          this.openEditor();
        }));
      }

      // Populate country select boxes passed from server
      this._populateSelectBox(this.countries, this.shippingCountry);
      this._populateSelectBox(this.countries, this.billingCountry);

      // Populate US state select boxes passed from server
      this._populateSelectBox(this.usStates, this.shippingState);
      this._populateSelectBox(this.usStates, this.billingState);

      // Query shipping, billing form fields.
      this.shippingInputs = query("input, select, checkbox", this.shippingFormData);
      this.billingInputs = query("input, select, checkbox", this.billingFormData);

      // Mixin params from controller
      lang.mixin(this.state, this.params);
    },
    /**
     * Show billing editor on the users landing page
     */
    openEditor: function () {
      if (this.loggingUrl) {
        this.state.feguid = this.createGuid();
      }
      // Scroll to top of editor
      this._scrollToTop();
      // Prevent scroll on body
      domClass.add(document.body, "editorOpen");
      // Show billing editor
      this._show(this.editorContainer, "slide");
      this.log("LPBE-Opened");
    },
    /**
     * Submits an XHR to the logging endpoint to hit ES
     *
     * @param {string} event to be logged
     * @param {object} additionalParams to be logged
     * @param {string} method level to be logged
     */
    log: function (event, additionalParams, method) {
      // If we don't have the flag set - this "loggingUrl" will equal "null"
      if (this.loggingUrl) {
        // Always log state
        var params = Object.assign({}, this.state, additionalParams, {
          event: event,
          method: method
        });
        request.get(this.loggingUrl, params);
      }
    },
    /**
     * General purpose function to create a GUID to store per unique 'session' in the UBE
     *
     * @returns {string} - a GUID
     */
    createGuid: function () {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }
      return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
    },
    /**
     * Hide billing editor on the users landing page
     */
    closeEditor: function () {
      // Enable scroll on body
      domClass.remove(document.body, "editorOpen");
      // Hide billing editor
      this._hide(this.editorContainer, "slide");
      this.log("LPBE-Closed", {
        abandoned: this.state.step !== "confirmation"
      });

      // Reset form if user has completed purchase
      if (this.state.step === "confirmation") {
        this._resetForm();
      }
    },
    /**
     * Handles the "Edit" button click of a particular section of the form
     *
     * @param {*} e - event
     */
    editSection: function (e) {
      var section = e.target.getAttribute("name");
      if (section) {
        // Update step
        this.state.step = section;

        // Show selected step in UI
        this._updateFormStep();
        this.log("Section-Edited", {
          section: section
        });
      }
    },
    /**
     * Attached to the "Continue to billing" button in the editor.
     * Validates form data, then progresses user to the billing address section.
     *
     */
    submitShipping: function () {
      // Validate shipping form data
      var validData = true;
      array.forEach(this.shippingInputs, lang.hitch(this, function (input) {
        if (this.validateField(input) === false) {
          validData = false;
          this.log("Invalid-Shipping", {
            field_type: input.type,
            field_value: input.value
          }, "error");
        }
      }));
      if (validData) {
        // Gather form data
        var formData = domForm.toObject(this.shippingFormData);

        // Update address summary
        var summaryBlock = new _SummaryBlock({
          firstName: formData.shippingFirstName,
          lastName: formData.shippingLastName,
          address1: formData.shippingAddress1,
          address2: formData.shippingAddress2 || "",
          city: formData.shippingCity,
          state: formData.shippingState.toUpperCase() || formData.shippingProvince,
          zip: formData.shippingZip
        }).placeAt(this.shippingSummary, "only");

        // Update add subscriber value based on emailOptin checkbox
        formData.addSubscriber = this.emailOptin.checked;

        // Mix shipping form data into state
        lang.mixin(this.state, formData);
        this.state.shippingCountryCode = this.countryCodes[this.state.shippingCountry];

        // Advance editor to next step
        this._scrollToTop();
        this._advanceToNextStep();
      }
    },
    /**
     * Attached to the "Continue to payment" button in the editor.
     * Validates form data, then progresses user to the payment method section.
     *
     */
    submitBilling: function () {
      // If sameAsShipping is checked, skip validation
      if (this.sameAsShipping) {
        // Update address summary
        html.set(this.billingSummary, "Same as shipping address");

        // Update payment section card name
        domAttr.set(this.cardName, "value", this.state.shippingFirstName + " " + this.state.shippingLastName);

        // Advance editor to next step and return
        this._scrollToTop();
        this._advanceToNextStep();
        return;
      }

      // Validate billing form data
      var validData = true;
      array.forEach(this.billingInputs, lang.hitch(this, function (input) {
        if (this.validateField(input) === false) {
          validData = false;
          this.log("Invalid-Billing", {
            field_type: input.type,
            field_value: input.value
          }, "error");
        }
      }));
      if (validData) {
        // Gather form data
        var formData = domForm.toObject(this.billingFormData);

        // Update address summary
        var summaryBlock = new _SummaryBlock({
          firstName: formData.billingFirstName,
          lastName: formData.billingLastName,
          address1: formData.billingAddress1,
          address2: formData.billingAddress2 || "",
          city: formData.billingCity,
          state: formData.billingState.toUpperCase() || formData.billingProvince,
          zip: formData.billingZip
        }).placeAt(this.billingSummary, "only");

        // Mix billing form data into state
        lang.mixin(this.state, formData);

        // Update payment section card name
        domAttr.set(this.cardName, "value", this.state.billingFirstName + " " + this.state.billingLastName);

        // Advance editor to next step
        this._scrollToTop();
        this._advanceToNextStep();
      }
    },
    /**
     * @returns {object} the values from the shipping inputs formatted as a Stripe billing details object
     *
     * @link https://stripe.com/docs/api/payment_methods/create#create_payment_method-billing_details
     */
    getStripeShippingDetails: function () {
      return {
        name: this.state.shippingFirstName + " " + this.state.shippingLastName,
        address: {
          line1: this.state.shippingAddress1,
          line2: this.state.shippingAddress2 ? this.state.shippingAddress2 : null,
          city: this.state.shippingCity,
          country: this.countryCodes[this.state.shippingCountry],
          postal_code: this.state.shippingZip,
          state: this.state.shippingState
        }
      };
    },
    /**
     * @returns {object} the values from the billing inputs formatted as a Stripe billing details object
     *
     * @link https://stripe.com/docs/api/payment_methods/create#create_payment_method-billing_details
     */
    getStripeBillingDetails: function () {
      return {
        name: this.state.billingFirstName + " " + this.state.billingLastName,
        address: {
          line1: this.state.billingAddress1,
          line2: this.state.billingAddress2 ? this.state.billingAddress2 : null,
          city: this.state.billingCity,
          country: this.countryCodes[this.state.billingCountry],
          postal_code: this.state.billingZip,
          state: this.state.billingState
        }
      };
    },
    /**
     * Attached to the "Save" button in the payment section.
     */
    submitPaymentForm: function () {
      switch (this.params.storePlatform.toLowerCase()) {
        case "stripe":
          this.submitStripeForm();
          break;
        case "square":
          this.submitSquareForm();
          break;
      }
    },
    /**
     * Validate credit card data and request card nonce from Square HPF
     *
     */
    submitSquareForm: function () {
      // Request a nonce from the SqPaymentForm object
      this.paymentForm.requestCardNonce();
    },
    /**
     * Creates a Stripe Payment Intent and Stripe Payment Method
     */
    submitStripeForm: function () {
      var self = this;
      request.get(this.paymentIntentUrl, this.state).then(function (response) {
        if (response.status === "error") {
          self._showErrorNode("stripeCardError", "There was a problem with your request. Please try again.");
          return;
        }
        self.paymentIntentSecret = response.clientSecret;
        var billingContact = null;
        if (self.sameAsShipping) {
          billingContact = self.getStripeShippingDetails();
        } else {
          billingContact = self.getStripeBillingDetails();
        }

        // email is only collected for shipping contact
        billingContact.email = self.state.shippingEmail;
        self.paymentForm.createPaymentMethod({
          type: 'card',
          card: self.paymentInputs.getElement('cardNumber'),
          billing_details: billingContact
        }).then(function (result) {
          if (result.error) {
            self._showErrorNode("stripeCardError", result.error.message);
            return;
          }
          if (result.paymentMethod) {
            self.paymentMethodSecret = result.paymentMethod.id;

            // Update UI with card data
            html.set(self.confirmationCardType, result.paymentMethod.card.brand);
            html.set(self.confirmationCardLastFour, result.paymentMethod.card.last4);
            html.set(self.paymentCardType, result.paymentMethod.card.brand);
            html.set(self.paymentCardLastFour, result.paymentMethod.card.last4);
            html.set(self.paymentCardExpiration, result.paymentMethod.card.exp_month + "/" + result.paymentMethod.card.exp_year.toString().substring(2));

            // Hide HPF and show payment summary
            self._hide(self.feedbackBlock);
            self._hide(self.paymentContainer);
            self._show(self.paymentSummary);
            self._show(self.paymentToggle);
            self._show(self.paymentCheck);
            self._toggleSubmitButton("enabled");
          }
        });
      });
    },
    /**
     * Attached to the "Complete order" button.
     */
    submitPayment: function () {
      // Show loading icon
      this._show(this.loadingContainer, "fade");
      switch (this.params.storePlatform.toLowerCase()) {
        case "stripe":
          this.submitStripePayment();
          break;
        case "square":
          this.submitSquarePayment();
          break;
      }
    },
    /**
     * Passes form data to the controller via JSONP request, then progresses user to the confirmation screen.
     */
    submitSquarePayment: function () {
      request.get(this.purchaseUrl, this.state).then(lang.hitch(this, function (response) {
        if (response.status === "error") {
          this._handleErrorResponse(response);
        } else {
          this._handleSuccessResponse();
        }
        // Clear loading icon
        this._hide(this.loadingContainer, "fade");
      }));
    },
    /**
     * Completes Stripes Payment, then progresses user to the confirmation screen.
     */
    submitStripePayment: function () {
      var paymentData = {
        payment_method: this.paymentMethodSecret,
        shipping: this.getStripeShippingDetails(),
        receipt_email: this.state.shippingEmail
      };
      this.paymentForm.confirmCardPayment(this.paymentIntentSecret, paymentData).then(lang.hitch(this, function (response) {
        if (response.error) {
          this._handleErrorResponse(response);
          this._hide(this.loadingContainer, "fade");
        } else {
          this.state.orderForeignId = response.paymentIntent.id;
          this.state.orderProcessedAt = response.paymentIntent.created;
          this.handleStripePostPurchase();
        }
      }));
    },
    /**
     * Handle Stripe post purchase actions
     */
    handleStripePostPurchase: function () {
      request.get(this.postPurchaseUrl, this.state).then(lang.hitch(this, function (response) {
        this._handleSuccessResponse();
        this._hide(this.loadingContainer, "fade");
      }));
    },
    /**
     * Toggle billing editor fields when same as shipping is checked
     *
     * @param {*} e - event
     */
    toggleSameAsShipping: function (e) {
      // Collapse billing input section
      if (e.target.checked) {
        this._hide(this.billingInputContainer, "fade");
        this.sameAsShipping = true;
      } else {
        this._show(this.billingInputContainer, "fade");
        this.sameAsShipping = false;
      }
    },
    /**
     * Toggle Square form editor in payment section
     *
     * @param {*} e - event
     */
    togglePaymentEditor: function (e) {
      if (e.target.dataset.dojoAttachPoint === "paymentCancel") {
        this._hide(this.paymentContainer);
        this._show(this.paymentSummary);
        this._show(this.paymentToggle);
        // Enable submit button
        this._toggleSubmitButton("enabled");
      } else {
        this._show(this.paymentCancel);
        this._show(this.paymentContainer);
        this._hide(this.paymentSummary);
        this._hide(this.paymentToggle);
        this._hide(this.paymentCheck);
        // Disable submit button
        this._toggleSubmitButton("disabled");
      }
    },
    /**
     * Toggle order summary section for mobile
     */
    toggleOrderSummary: function () {
      // Check for open container then show/hide
      if (domClass.contains(this.orderSummary, "openSummary")) {
        domClass.remove(this.orderSummary, "openSummary");
      } else {
        domClass.add(this.orderSummary, "openSummary");
      }
    },
    /**
     * Handle country input change to find if country has a state
     *
     * @param {*} e - event
     */
    countryInputChange: function (e) {
      var selectedCountry = parseInt(e.target.value, 10);
      var section = e.target.id.split("Country")[0];
      var stateContainer, provinceContainer, state, province;
      if (section === "shipping") {
        stateContainer = this.shippingStateContainer;
        provinceContainer = this.shippingProvinceContainer;
        state = this.shippingState;
        province = this.shippingProvince;
      } else {
        // Assume billing section
        stateContainer = this.billingStateContainer;
        provinceContainer = this.billingProvinceContainer;
        state = this.billingState;
        province = this.billingProvince;
      }

      // If United States, show US states select; US country code is 164
      if (selectedCountry === 164) {
        this._show(stateContainer);
        this._hide(provinceContainer);
        domAttr.set(province, "type", "hidden");
        domAttr.set(state, "value", "AL");
        return;
      }

      // Else check if country has state
      var hasState = this._countryIsInList(selectedCountry, this.countriesWithStates);
      if (hasState) {
        // Show state/province input
        this._hide(stateContainer);
        this._show(provinceContainer);
        domAttr.set(state, "value", "");
        domAttr.set(province, "type", "text");
      } else {
        // Hide both inputs
        this._hide(stateContainer);
        this._hide(provinceContainer);
        domAttr.set(state, "value", "");
        domAttr.set(province, "value", "");
        domAttr.set(province, "type", "hidden");
      }
    },
    /**
     * Returns whether or not a specific country is found in our country list
     *
     * @param {int} country - a single country
     * @param {array} list - list of counrties
     *
     * @returns {*} - true or false indicating whether or not the country was found
     */
    _countryIsInList: function (country, list) {
      var countryInList = false;
      array.forEach(list, lang.hitch(this, function (item) {
        for (var key in item) {
          if (item.hasOwnProperty(key)) {
            if (country === item[key]) {
              countryInList = true;
            }
          }
        }
      }));
      return countryInList;
    },
    /**
     * Populate specific select box in the form with data from server
     *
     * @param {object} data - json data passed from server
     * @param {*} targetInput - selectbox to populate with data
     */
    _populateSelectBox: function (data, targetInput) {
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          var el = "<option value='" + key + "'>" + data[key] + "</option>";
          domConstruct.place(el, targetInput, "last");
        }
      }
    },
    /**
     * Advance to the next step in the editor
     */
    _advanceToNextStep: function () {
      var nextStep = this._getNextStep();
      this.log("Step-Advancing", {
        fromStep: this.state.step,
        toStep: nextStep
      });

      // Update step
      this.state.step = nextStep;

      // Show selected step in UI
      this._updateFormStep();
    },
    /**
     * Gets the next step based on the current step in state
     *
     * @returns {string} - the next step
     */
    _getNextStep: function () {
      var step = this.state.step;
      var currentStep = this.steps.indexOf(step);
      return this.steps[1 + currentStep];
    },
    /**
     * Show the current step in the editor, hide all of the others
     */
    _updateFormStep: function () {
      switch (this.state.step) {
        case "shipping":
          this._hide(this.billingContainer);
          this._hide(this.billingToggle);
          this._hide(this.billingCheck);
          this._hide(this.shippingCheck);
          this._hide(this.shippingToggle);
          this._hide(this.paymentToggle);
          this._hide(this.paymentContainer);
          this._hide(this.paymentSummary);
          this._hide(this.paymentCheck);
          this._hide(this.shippingSummary);
          this._hide(this.billingSummary);
          this._show(this.shippingContainer, "fade");
          this._toggleSubmitButton("disabled");
          break;
        case "billing":
          this._hide(this.shippingContainer);
          this._hide(this.paymentToggle);
          this._hide(this.paymentContainer);
          this._hide(this.paymentSummary);
          this._hide(this.paymentCheck);
          this._hide(this.billingToggle);
          this._hide(this.billingCheck);
          this._hide(this.billingSummary);
          this._show(this.shippingCheck);
          this._show(this.shippingSummary);
          this._show(this.shippingToggle);
          this._show(this.billingContainer, "fade");
          this._toggleSubmitButton("disabled");
          domClass.remove(this.billingWrapper, "opacity__Dim");
          break;
        case "payment":
          this._hide(this.billingContainer);
          this._show(this.billingCheck);
          this._show(this.billingToggle);
          this._show(this.billingSummary);
          this._hide(this.paymentCheck);
          domClass.remove(this.paymentWrapper, "opacity__Dim");

          // If card details are available, show payment summary
          if (this.state.nonce) {
            this._show(this.paymentSummary);
            this._show(this.paymentToggle);
            this._toggleSubmitButton("enabled");
          } else {
            this._show(this.paymentContainer);
            if (!this.paymentForm) {
              this._intitializePaymentForm();
            }
            this._hide(this.paymentToggle);
          }
          break;
        case "confirmation":
          this._hide(this.formContainer);
          this._show(this.confirmationContainer, "fade");
          break;
        default:
          break;
      }
    },
    _intitializePaymentForm: function () {
      switch (this.params.storePlatform.toLowerCase()) {
        case "stripe":
          this._initializeStripePaymentForm();
          break;
        case "square":
          this._initializeSquarePaymentForm();
          break;
      }
    },
    _initializeStripePaymentForm: function () {
      this._createStripePaymentForm(this.params.publishableKey, this.params.storeForeignId);
    },
    /**
     * Initialize Square payment form and update payment section inputs
     */
    _initializeSquarePaymentForm: function () {
      this.paymentForm = this._createSquarePaymentForm(this.params.squareApplicationId, this.params.squareLocationId);

      // Call the dom handler so that the form inputs are replaced
      // with iframes by Square's library
      this.paymentForm._handleDomContentLoaded();
    },
    /**
     * Hide passed editor container
     *
     * @param {*} container - container node to hide
     * @param {*} animation - velocity animation to use
     */
    _hide: function (container, animation) {
      // Check for animation
      if (animation === "slide") {
        Velocity(container, "transition.slideUpOut", {
          "easing": "easeOutQuad",
          "duration": 200,
          "display": "none"
        });
      } else if (animation === "fade") {
        Velocity(container, "fadeOut", {
          "duration": 100,
          "display": "none"
        });
      } else {
        domStyle.set(container, "display", "none");
      }
    },
    /**
     * Show passed editor container
     *
     * @param {*} container - container node to show
     * @param {*} animation - velocity animation to use
     */
    _show: function (container, animation) {
      // Check for animation
      if (animation === "slide") {
        Velocity(container, "transition.slideDownIn", {
          "easing": "easeInQuad",
          "duration": 400,
          "display": ""
        });
      } else if (animation === "fade") {
        Velocity(container, "fadeIn", {
          "duration": 400,
          "display": ""
        });
      } else {
        domStyle.set(container, "display", "");
      }
    },
    /**
     * Enable/disable submit button
     *
     * @param {*} type - enable or disable element
     */
    _toggleSubmitButton: function (type) {
      // Toggle submit buttons in UI
      if (type === "disabled") {
        domClass.add(this.submitButton, "disabled");
        domClass.add(this.mobileSubmitButton, "disabled");
      } else {
        domClass.remove(this.submitButton, "disabled");
        domClass.remove(this.mobileSubmitButton, "disabled");
      }
    },
    /**
     * Scroll view to the top of window
     */
    _scrollToTop: function () {
      // Scroll to top of editor
      setTimeout(lang.hitch(this, function () {
        this.editorContainer.scrollTo(0, 0);
      }), 50);

      // Collapse order summary for mobile
      domClass.remove(this.orderSummary, "openSummary");
    },
    /**
     * Handle success response from client-side GET requests
     */
    _handleSuccessResponse: function () {
      // Gather shipping form data
      var formData = domForm.toObject(this.shippingFormData);

      // Populate confirmation page information
      html.set(this.confirmationName, "Thanks, " + formData.shippingFirstName + "!");
      html.set(this.confirmationEmail, formData.shippingEmail);
      var summaryBlock = new _SummaryBlock({
        firstName: formData.shippingFirstName,
        lastName: formData.shippingLastName,
        address1: formData.shippingAddress1,
        address2: formData.shippingAddress2 || "",
        city: formData.shippingCity,
        state: formData.shippingState.toUpperCase() || formData.shippingProvince,
        zip: formData.shippingZip
      }).placeAt(this.confirmationSummary, "only");
      this.log("Successful-Purchase");

      // Advance editor to next step
      this._advanceToNextStep();
    },
    /**
     * Handle error response from client-side GET requests
     *
     * @param {object} response - data returned from controller
     */
    _handleErrorResponse: function (response) {
      var message = "";
      switch (this.params.storePlatform.toLowerCase()) {
        case "stripe":
          message = this.handleStripeErrorResponse(response);
          break;
        case "square":
          message = this.handleSquareErrorResponse(response);
          break;
      }

      // Insert HPF error message
      html.set(this.feedbackMessage, message);
      // Show HPF error message
      this._show(this.feedbackBlock, "fade");
      // Hide payment summary
      this._show(this.paymentContainer);
      this._hide(this.paymentSummary);
      // Disable submit button
      this._toggleSubmitButton("disabled");
    },
    handleStripeErrorResponse: function (response) {
      var message = "There was a problem with your request. Please try again.";
      this.log("Error-Purchase", {
        message: message,
        errors: response.error
      }, "error");
      return message;
    },
    handleSquareErrorResponse: function (response) {
      // Populate error data
      var message = "";

      /** @param {array} response.errors.square_errors */
      array.forEach(response.errors.square_errors, lang.hitch(this, function (error) {
        switch (error.code) {
          // Billing Zip Code doesn't match payments Zip Code
          case "BAD_REQUEST":
            message = "The postal code doesn’t match the billing address. Please confirm your information is correct and try again.";
            break;

          // OAuth Token has been revoked
          case "ACCESS_TOKEN_REVOKED":
            message = "The item is currently unavailable. Please try again later.";
            break;

          // Postal Code Check Failed
          case "VERIFY_AVS_FAILURE":
            message = "The postal code was unable to be verified. Please confirm your information is correct and try again.";
            break;

          // CVV Verification Failed
          case "VERIFY_CVV_FAILURE":
            message = "The CVV was unable to be verified. Please confirm your information is correct and try again. ";
            break;
          default:
            message = "Please confirm your payment information is correct and try again";
            break;
        }
      }));
      this.log("Error-Purchase", {
        message: message,
        errors: response.errors.square_errors
      }, "error");
      return message;
    },
    /**
     * Create new Square payment form
     *
     * @param {integer} applicationId - application id passed from controller
     * @param {integer} locationId - location id passed from controller
     *
     * @returns {object} - new Square payment form
     */
    _createSquarePaymentForm: function (applicationId, locationId) {
      var self = this;

      // eslint-disable-next-line no-undef
      return new SqPaymentForm({
        // Initialize the payment form elements
        applicationId: applicationId,
        locationId: locationId,
        inputClass: "sq-input",
        // Customize the CSS for SqPaymentForm iframe inputs
        inputStyles: [{
          fontSize: "15px",
          fontFamily: '"Helvetica Neue", "Helvetica", Arial, Verdana, sans-serif',
          fontWeight: "500",
          padding: "6px",
          color: "#3C3C3C",
          lineHeight: "20px",
          placeholderColor: "#737373",
          placeholderFontWeight: "500",
          _webkitFontSmoothing: "antialiased",
          _mozOsxFontSmoothing: "grayscale"
        }],
        // Initialize the credit card placeholders
        cardNumber: {
          elementId: "cardNumber"
        },
        cvv: {
          elementId: "cvv"
        },
        expirationDate: {
          elementId: "expirationDate",
          placeholder: "MM/YY"
        },
        postalCode: {
          elementId: "postalCode"
        },
        // SqPaymentForm callback functions
        callbacks: {
          /*
           * callback function: cardNonceResponseReceived
           * Triggered when: SqPaymentForm completes a card nonce request
           *
           * Ex. cardData {
                "digital_wallet_type": "NONE",
                "card_brand": "VISA",
                "last_4": "1111",
                "exp_month": 1,
                "exp_year": 2019,
                "billing_postal_code": "30302"
              }
           */
          cardNonceResponseReceived: function (errors, nonce, cardData) {
            if (errors) {
              // Clear all Square input errors
              self._clearInputErrors();

              // Show new Square input errors
              array.forEach(errors, function (error) {
                self._showErrorNode(error.field, error.message);
              });
            } else {
              // Clear all Square input errors
              self._clearInputErrors();

              // Update UI with card data
              html.set(self.confirmationCardType, cardData.card_brand.replace(/_/g, " "));
              html.set(self.confirmationCardLastFour, cardData.last_4);
              html.set(self.paymentCardType, cardData.card_brand.replace(/_/g, " "));
              html.set(self.paymentCardLastFour, cardData.last_4);
              html.set(self.paymentCardExpiration, cardData.exp_month + "/" + cardData.exp_year.toString().substring(2));

              // Hide HPF and show payment summary
              self._hide(self.feedbackBlock);
              self._hide(self.paymentContainer);
              self._show(self.paymentSummary);
              self._show(self.paymentToggle);
              self._show(self.paymentCheck);

              // Add nonce and cardData into state
              self.state.nonce = nonce;
              lang.mixin(self.state, cardData);

              // Build payment method verification request.
              var billingContact = null;
              var details = {
                amount: self.state.payment_total_no_symbol,
                currencyCode: self.state.payment_currency,
                intent: "CHARGE"
              };
              if (self.sameAsShipping) {
                billingContact = {
                  familyName: self.state.shippingLastName,
                  givenName: self.state.shippingFirstName,
                  email: self.state.shippingEmail,
                  country: self.countryCodes[self.state.shippingCountry],
                  city: self.state.shippingCity,
                  postalCode: self.state.shippingZip
                };
                var shippingAddressLines = [self.state.shippingAddress1];
                if (self.state.shippingAddress2) {
                  shippingAddressLines.push(self.state.shippingAddress2);
                }
                billingContact.addressLines = shippingAddressLines;
              } else {
                billingContact = {
                  familyName: self.state.billingLastName,
                  givenName: self.state.billingFirstName,
                  email: self.state.billingEmail,
                  country: self.countryCodes[self.state.billingCountry],
                  city: self.state.billingCity,
                  postalCode: self.state.billingZip
                };
                var billingAddressLines = [self.state.billingAddress1];
                if (self.state.billingAddress2) {
                  billingAddressLines.push(self.state.billingAddress2);
                }
                billingContact.addressLines = billingAddressLines;
              }
              details.billingContact = billingContact;

              // Verifies transaction details Square-side for SCA.
              self.paymentForm.verifyBuyer(nonce, details, function (error, verification) {
                if (error === null) {
                  self.state.verification = verification.token;
                  // Enable submit button
                  self._toggleSubmitButton("enabled");
                } else {
                  self._handleErrorResponse({
                    errors: {
                      square_errors: [error]
                    }
                  });
                }
              });
            }
          },
          paymentFormLoaded: function () {
            // Set postal code for Square Input
            var postalCode = self.sameAsShippingInput.checked ? self.state.shippingZip : self.state.billingZip;
            self.paymentForm.setPostalCode(postalCode);
          }
        }
      });
    },
    /**
     * Create new Square payment form
     */
    _createStripePaymentForm: function (publishableKey, storeId) {
      // eslint-disable-next-line no-undef
      this.paymentForm = Stripe(publishableKey, {
        stripeAccount: storeId
      });
      this.paymentInputs = this.paymentForm.elements();
      var options = {
        style: {
          base: {
            fontFamily: "'Helvetica Neue', Arial, Helvetica, Verdana, sans-serif",
            fontSize: "15px",
            lineHeight: "30px",
            fontWeight: "normal"
          }
        }
      };
      var cardNumber = this.paymentInputs.create("cardNumber", options);
      var cardExpiry = this.paymentInputs.create("cardExpiry", options);
      var cardCvc = this.paymentInputs.create("cardCvc", options);
      cardNumber.mount("#cardNumberInput");
      cardExpiry.mount("#cardExpirationInput");
      cardCvc.mount("#cardCvvInput");
      cardNumber.on('change', lang.hitch(this, function (event) {
        if (event.error) {
          this._showErrorNode("stripeCardError", event.error.message);
        } else {
          this._showErrorNode("stripeCardError");
        }
      }));
      cardExpiry.on('change', lang.hitch(this, function (event) {
        if (event.error) {
          this._showErrorNode("stripeCardError", event.error.message);
        } else {
          this._hideErrorNode("stripeCardError");
        }
      }));
    },
    /**
     * Hides iFrame error nodes for the form.
     */
    _clearInputErrors: function () {
      this._hideErrorNode("cardNumber");
      this._hideErrorNode("expirationDate");
      this._hideErrorNode("cvv");
      this._hideErrorNode("postalCode");
      this._hideErrorNode("stripeCardError");
    },
    /**
     * Reset the form and all data in it
     */
    _resetForm: function () {
      // Reset shipping inputs
      array.forEach(this.shippingInputs, lang.hitch(this, function (input) {
        if (input.type !== "select-one") {
          domAttr.set(input, "value", "");
        }
      }));
      // Reset billing inputs
      array.forEach(this.billingInputs, lang.hitch(this, function (input) {
        if (input.type !== "select-one") {
          domAttr.set(input, "value", "");
        }
      }));
      // Reset state
      this.state.nonce = null;
      this.state.step = "shipping";
      this._updateFormStep();
      // Reset same as shipping
      this.sameAsShippingInput.checked = false;
      this.sameAsShipping = false;
      this._show(this.billingInputContainer, "fade");
      // Show form
      this._show(this.formContainer);
      this._hide(this.confirmationContainer);
    }
  });
});